<template>
  <div :lang="$i18n.locale">
    <!-- 更新FBA信息 -->
    <el-dialog
    :title="$t('chinaWhB2BOrderPage.actionModifyFBA')"
    :close-on-click-modal="false"
    :visible.sync="popup"
    width="880px"
    :before-close="handleDialogClose"
		v-loading="fbaLoading"
    >
      <el-form label-width="140px" :model="fbaForm" ref="fbaForm" :inline="true">
      	<el-divider content-position="left">{{$t('commonInfo.orderInfo')}}</el-divider>
      	<el-form-item :label="$t('commonInfo.deliveryCountry')" prop="destCountry">
      		<el-input v-model="fbaForm.destCountry" style="width: 250px;" disabled></el-input>
      	</el-form-item>
      	<el-form-item :label="$t('commonInfo.deliveryWarehouse')" prop="destWhNo">
      		<el-input v-model="fbaForm.destWhNo" style="width: 250px;" disabled></el-input>
      	</el-form-item>
      	<el-form-item :label="$t('commonInfo.deliveryDest')" prop="otherDest">
      		<el-input v-model="fbaForm.otherDest" style="width: 250px;" disabled></el-input>
      	</el-form-item>
      	<el-form-item :label="$t('commonInfo.fbaNo')" prop="fbaNo">
      		<el-input v-model="fbaForm.fbaNo" style="width: 250px;" disabled></el-input>
      	</el-form-item>
      	<el-form-item :label="$t('commonInfo.fbaNoCode')" prop="fbaNoCode">
      		<el-input v-model="fbaForm.fbaNoCode" style="width: 250px;" disabled></el-input>
      	</el-form-item>
      	<el-form-item :label="$t('commonInfo.skuCount')" prop="skuCount">
      		<el-input v-model="fbaForm.skuCount" style="width: 250px;" disabled></el-input>
      	</el-form-item>
      	<el-form-item :label="$t('commonInfo.declaredValue')" prop="declaredValue">
      		<el-input v-model="fbaForm.declaredValue" style="width: 250px;" disabled></el-input>
      	</el-form-item>
      	<el-form-item :label="$t('commonInfo.packageAmount')" prop="packageCount">
      		<el-input v-model="fbaForm.packageCount" style="width: 250px;" disabled></el-input>
      	</el-form-item>
      	<el-divider content-position="left">{{$t('chinaWhB2BOrderPage.dialogModifyFBANewFBAInfo')}}</el-divider>
      	<el-form-item :label="$t('commonInfo.fbaNo')" prop="afterFbaNo"
      		:rules="{required:true,message:$t('tipsInfo.inputPlaceholder') + ' ' + $t('commonInfo.fbaNo'),trigger:'blur'}">
      		<el-input v-model="fbaForm.afterFbaNo" style="width: 250px;"></el-input>
      	</el-form-item>
      	<el-form-item :label="$t('commonInfo.fbaNoCode')" prop="afterFbaNoCode" :rules="[{required:true,message:$t('tipsInfo.inputPlaceholder') + ' ' + $t('commonInfo.fbaNoCode'),trigger:'blur'},
      	{min:8,max:8,message:$t('commonInfo.fbaNoCodeLimit'),trigger:'change'}]">
      		<el-input v-model="fbaForm.afterFbaNoCode" style="width: 250px;"></el-input>
      	</el-form-item>
      </el-form>
      <el-footer style="height: 140px; position: relative;">
      	<div class="uploadBtn">
      		<!-- 上传FBA附件 -->
      		<SingleUploadPDF v-model="updateFbaFile" :tipText="$t('commonInfo.fbxFileTips')"
      			:preFile="updateFbxPreName" @input="getupdateFile"
      			>
      		</SingleUploadPDF>
      	</div>
      	<el-button class="submitBtn" type="primary" :loading="submitFbaLoading" :disabled="submitFbaLoading" @click="submitFBA"
      		>
      		{{submitFbaLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
      	</el-button>
      </el-footer>
    </el-dialog>
  </div>

</template>

<script>
	import SingleUploadPDF from "@/components/upload/singleUploadPDF";
  export default {
    name: 'UpdateFBAInfo',
		components:{
			SingleUploadPDF
		},
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      },
      rowId:{
        type:String,
        default:function(){
        	return '';
        }
      },
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
          if(newValue){
            this.popup = newValue;
            this.getInfo(this.rowId);
          }
      	},
      	immediate: true,
      },
    },
    data() {
      return {
        popup:false,
        fbaLoading:false,
				submitFbaLoading:false,
        
				updateFbxLink: '',
				updateFbxPreName: [],
				updateFbaFile: '',
        fbaForm:{
					afterFbaNo: '',
					afterFbaNoCode: '',
				},
      }
    },

    //方法集合
    methods: {
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
        this.updateFbxPreName = [];
        this.updateFbxLink = '';
        this.fbaForm = {
        	afterFbaNo: '',
        	afterFbaNoCode: '',
        }
        this.$parent.updateFBAPopup = false;
        // 清除数据
        this.$parent.rowId = '';
      },
			//获取上传FBA返回的路径
			getupdateFile(data) {
				this.updateFbxLink = data;
				if (data != '') {
					//修改upload的preview预览
					let str = data.split("~");
					this.updateFbxPreName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			//修改FBA
			async getInfo(id) {
				this.fbaLoading = true;
				const res = await this.$http.get("/toborder/detail/" + id);
				if (res.code === 200) {
					this.fbaForm.destCountry = res.data.toBOrderInfo.destCountry;
					this.fbaForm.destWhNo = res.data.destWhNo == "" ? this.$t('commonInfo.customeWarehouse') : res.data
						.destWhNo;
					this.fbaForm.otherDest = res.data.toBOrderInfo.otherDest;
					this.fbaForm.fbaNo = res.data.toBOrderInfo.fbaNo;
					this.fbaForm.fbaNoCode = res.data.toBOrderInfo.fbaNoCode;
					this.fbaForm.skuCount = res.data.toBOrderInfo.skuCount;
					this.fbaForm.declaredValue = res.data.toBOrderInfo.declaredValue;
					this.fbaForm.packageCount = res.data.lgPackageList.length;
					this.fbaLoading = false;
				} else {
					this.fbaLoading = false;
					this.$message.error(res.msg);
				}
			
			},
			//提交修改信息
			submitFBA() {
				if (this.updateFbxLink == '') {
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.dialogModifyFBANewFBATips'));
				}
				let that = this;
			
				let formObj = {
					orderId: this.rowId,
					afterFbaCode: this.fbaForm.afterFbaNoCode,
					afterFbaNo: this.fbaForm.afterFbaNo,
					afterAttachment: this.updateFbxLink,
				}
				this.$refs['fbaForm'].validate((vaild) => { //表单验证
					if (vaild) {
						that.submitFbaLoading = true;
						that.$http.post("/toborder/update/fba", formObj).then(res => {
							if (res.code == 200) {
								that.$message.success(this.$t('tipsInfo.success'))
								that.submitFbaLoading = false;
								that.handleDialogClose();
								that.$parent.getlist();
							} else {
								that.submitFbaLoading = false;
								that.$message.error(res.msg);
							}
						}).catch(err => {
							that.submitFbaLoading = false;
							that.$message.error(err.msg);
						})
					}
				})
			},
    },
    created() {
    },

  }
</script>

<style scoped>
	.uploadBtn{
		width: 120px;
		float: left;
		margin-left: 20px;
	}
	.submitBtn{
		position: absolute;
		right: 50px;
		top: 70px;
	}
	[lang='ar'] .uploadBtn {
		float: right; 
		margin-right: 10px;
	}
	[lang='ar'] .submitBtn {
		left: 50px;
		right: auto;
	}
</style>
