<template>
	<div class="app-container" 
	:lang="$i18n.locale"
	v-loading.fullscreen="uploadloading" :element-loading-text="$t('tipsInfo.uploading')"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
		<div class="listHeader">
			<i></i><span>{{$t('B2BOrder.airTransportOrderList')}}</span>
		</div>
		<el-form style="margin:10px 0 0 0" :model="queryParams" ref="queryParams" :inline="true"
			label-width="auto" @submit.native.prevent>
			<el-form-item label prop="orderNo">
				<el-input v-model.trim="queryParams.orderNo" clearable
					:placeholder="$t('commonInfo.orderNoColumn')" style="width: 200px;"></el-input>
			</el-form-item>
			<el-form-item label prop="">
				<el-date-picker v-model="orderTime" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
					range-separator="-" :start-placeholder="$t('commonInfo.beginTime')"
					:end-placeholder="$t('commonInfo.endTime')" style="width:300px"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button icon="el-icon-search" type="primary" @click="handleSearch">
					{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button icon="el-icon-refresh" type="primary" @click="getlist">
					{{$t('commonInfo.refreshBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}
				</el-button>
				<el-button icon="el-icon-plus" type="primary" @click="addOrder">
					{{$t('commonInfo.createAirOrder')}}</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="tableData" ref="b2bOrderTable" border style="width: 100%;font-size: 16px;"
			v-loading="loading" :row-key="rowKey" :expand-row-keys="expandRowArray"
			:row-class-name="setClassName">
			<el-table-column type="expand" label="" align="center" width="1">
				<template slot-scope="{row}">
					<el-row class="expandDataRow" v-for="subOrder in row.subOrderList" :key="subOrder.id"
						type="flex" justify="center" align="middle">
						<el-col :span='3'>
							<img style="width: 100px;height: 100px; margin: 0px auto; display: block;"
								src="../../../assets/images/subOrder.png" />
						</el-col>
						<el-col :span="21">
							<el-descriptions :column="2" border
								:labelStyle="{'width':'160px','text-align':'right','font-weight':'bold'}">
								<el-descriptions-item :label="$t('commonInfo.batch')">{{subOrder.batchCount}}
								</el-descriptions-item>
								<el-descriptions-item :label="$t('commonInfo.packageAmount')">
									{{subOrder.packageAmount}}</el-descriptions-item>
								<el-descriptions-item :label="$t('commonInfo.time')">{{subOrder.initTime}}
								</el-descriptions-item>
								<el-descriptions-item :label="$t('commonInfo.statusColumn')">{{subOrder.memo}}
								</el-descriptions-item>
							</el-descriptions>
						</el-col>
					</el-row>
				</template>
			</el-table-column>
		
			<el-table-column :label="$t('commonInfo.orderNoColumn')" prop="esnadTobOrderNo" min-width="160"
				align="center"></el-table-column>
			<el-table-column :label="$t('commonInfo.clientOrderNoColumn')" prop="clientOrderNo" min-width="160"
				align="center">
				<template slot-scope="{row}">
					<span>{{row.clientOrderNo==''||row.clientOrderNo==null?$t('commonInfo.noInfo'):row.clientOrderNo}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.packageAmount')" prop="packageAmount" min-width="100"
				align="center">
				<template slot-scope="scope">{{ scope.row.packageAmount }}</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.deliveryWarehouse')" prop="dest" min-width="160"
				align="center">
				<template slot-scope="scope">
					{{ scope.row.dest == "custom"?$t('commonInfo.customeWarehouse'):scope.row.dest }}
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.statusColumn')" min-width="120" align="center">
				<template slot-scope="scope">
					<div>
						<span style="display: block;">{{returnStatusName(scope.row)}}</span>
						<div v-if="isShowPod(scope.row)">
							<el-button type="text" icon="el-icon-picture" style="height: 22px; padding: 0px;"
								size="small" @click="checkPOD(scope.row.podLink)">
								{{$t('commonInfo.actionCheckPOD')}}</el-button>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column :label="$t('B2BOrder.examineStatus')" align="center" min-width="120"
				prop="examineFlag">
				<template slot-scope="{row}">
					<span>{{row.examineFlag?$t('chinaWhB2BOrderPage.Approved'):$t('chinaWhB2BOrderPage.underReview')}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.initTime')" prop="initTime" align="center" min-width="180">
			</el-table-column>
			<el-table-column :label="$t('commonInfo.operationColumn')" class-name="small-padding fixed-width"
				fixed="right" width="120" align="center" style="padding-right:0">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleCheckPackageInfo(row)"
						style="margin-left:10px" icon="el-icon-box">{{$t('chinaWhB2COrderPage.squareWeighing')}}
					</el-button>
					<el-button type="text" size="small" @click="handleCheckOTrackingInfo(row.esnadTobOrderNo)"
						icon="el-icon-s-promotion">{{$t('commonInfo.actiontrack')}}</el-button>
					<el-button v-if="isShowPDF(row) && row.waybillLink != null" type="text" size="small"
						@click="PDFSheet(row.waybillLink)" icon="el-icon-download">
						{{$t('commonInfo.downloadBoxStickers')}}
					</el-button>
					<el-button size="mini" v-else type="text" disabled icon="el-icon-loading">
						{{$t('commonInfo.boxStickersGenerating')}}</el-button>
					<el-dropdown style="margin-left: 10px;" @command="handleCommand">
						<span style="font-size: 12px; color: #409EFF;"
							class="el-dropdown-link">{{$t('commonInfo.moreAction')}}<i
								class="el-icon-arrow-down el-icon--right"></i></span>
						<el-badge v-if="row.subOrderList != null && row.subOrderList.length > 0" is-dot
							class="item batchInfoItem">
						</el-badge>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-if="isEditOrder(row)" :command="{'command':'a','row':row}">
								{{ $t('commonInfo.modifyOrder') }}</el-dropdown-item>
							<el-dropdown-item v-if="isEditFBA(row)" :command="{'command':'b','row':row}">
								{{ $t('chinaWhB2BOrderPage.actionModifyFBA') }}</el-dropdown-item>
							<el-dropdown-item :command="{'command':'c','row':row}">
								{{ $t('commonInfo.actionDownloadSkuList') }}</el-dropdown-item>
							<el-badge :lang="$i18n.locale" v-if="row.subOrderList != null && row.subOrderList.length > 0" is-dot
								class="item batchInfoItem1">
								<el-dropdown-item :command="{'command':'d','row':row}">
									{{ $t('chinaWhB2BOrderPage.batchTrackingTitle') }}</el-dropdown-item>
							</el-badge>
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<div>
			<el-image-viewer style="z-index: 4000;" v-if="showImg" :on-close="closeImg" :url-list="imgList" />
		</div>
		<div class="pageBottom">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryParams.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.limit"
				layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<!-- 创建订单窗口 -->
		<CreateOrder :isShow="createOrderPopup" :rowData="rowData" :optType="optType"></CreateOrder>
		<!-- 查看箱信息 -->
		<CheckCartonInfo :isShow="packageDialogPopup" :rowId="rowId"></CheckCartonInfo>
		<!-- 查看轨迹信息 -->
		<CheckTrackingInfo :isShow="checkTrackingPopup" :rowId="rowId"></CheckTrackingInfo>
		<!-- 修改FBA信息 -->
		<UpdateFBAInfo :isShow="updateFBAPopup" :rowId="rowId"></UpdateFBAInfo>
	</div>
</template>

<script>
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	import {
		formatDate
	} from "@/utils/index";
	
	import CreateOrder from './createOrder.vue'
	import CheckCartonInfo from './checkCartonInfo.vue'
	import CheckTrackingInfo from './checkTrackingInfo.vue'
	import UpdateFBAInfo from './updateFBAInfo.vue'
	
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
	export default {
		components: {
			ElImageViewer,
			CreateOrder,
			CheckCartonInfo,
			CheckTrackingInfo,
			UpdateFBAInfo
		},
		data() {
			return {
				//空运
				uploadloading:false,
				
				queryParams: {
					page: 1,
					limit: 10,
					orderNo: '',
					start: '',
					end: '',
				},
				orderTime: null,
				tobOrderNo: '',
				tableData: [],
				expandRowArray: [], // 默认展开id数组
				loading: false,
				total: 0,
				frontOrafter: '1', // 0 前置审核 1 后置审核
				
				// 创建订单
				createOrderPopup:false,
				rowData:{},
				optType:'',// add 新增，edit 修改
				
				// 查看箱信息
				packageDialogPopup: false,
				rowId:'',
				
				// 查看轨迹信息
				checkTrackingPopup:false,
				
				// 修改FBA号
				updateFBAPopup:false,
				
				// 预览图片
				imgList: [],
				showImg: false,
			}
		},
		//方法集合
		methods: {
			// 分页条数
			handleSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getlist();
			},
			// 分页页数
			handleCurrentChange(page) {
				this.queryParams.page = page;
				this.getlist();
			},
			rowKey(row) {
				return row.id;
			},
			// 搜索空运订单
			handleSearch() {
				if (this.orderTime != null && this.orderTime != '') {
					this.queryParams.start = this.orderTime[0];
					this.queryParams.end = this.orderTime[1];
				} else {
					this.queryParams.start = '';
					this.queryParams.end = '';
				}
				this.queryParams.page = 1;
				this.getlist();
			},
			// 重置
			handleReset() {
				this.orderTime = null;
				this.queryParams = {
					page: 1,
					limit: 10,
					orderNo: '',
					start: '',
					end: '',
				}
				this.getlist();
			},
			// 获取列表
			async getlist() {
				this.loading = true;
				this.tableData = [];
				const res = await this.$http.get("/toborder/customer", {
					params: this.queryParams
				});
			
				if (res.code === 200) {
					let data = res.data.list;
					// 总共有多少条数据，分页
					this.total = res.data.totalCount;
					let index = 1;
					data.forEach(item => {
						item.id = index += 1;
						item.expand = false; //默认关闭
						item["datas"] = [];
						item.initTime = formatDate(item.initTime);
					});
					this.tableData = data;
					this.loading = false;
				} else {
					this.$message.error(res.msg);
					this.loading = false;
				}
			},
			
			//关闭图片
			closeImg() {
				this.showImg = false;
			},
			//查看POD图片
			checkPOD(url) {
				this.imgList = [];
				//显示预览图片　
				if (url.length > 0) {
					var that = this;
					that.showImg = true;
					url.forEach(item => {
						that.imgList.push(item);
					})
				} else {
					this.$message.error(this.$t('tipsInfo.noPodInfo'));
				}
			
			},
			isShowPDF(row) {
				let isShow = false;
				if (this.frontOrafter == '0') { //前置审核
					if (row.examineFlag == false) { //未审核
						isShow = false;
					} else { //1审核通过
						isShow = true;
					}
				} else { // 1后置审核
					isShow = true;
				}
				return isShow;
			},
			isShowPod(row) { //判断数据状态
				let isShow = false;
				let statusNumber = row.status.substr(1);
				// 只有部分签收和全部签收 才能显示POD
				if (parseInt(statusNumber) == 710 || parseInt(statusNumber) == 800) {
					if (row.podLink != null && row.podLink != '') {
						isShow = true;
					}
				}
				return isShow
			},
			// 根据状态码显示信息 中英文
			returnStatusName(row) {
				let name = '';
				switch (row.status) {
					case '101':
						name = this.$t('B2BStatus.creating');
						break;
					case 'n100':
						name = this.$t('B2BStatus.init');
						break;
					case 'n101':
						name = this.$t('B2BStatus.warehousing');
						break;
					case 'n200':
						name = this.$t('B2BStatus.cnReceive');
						break;
					case 'n240':
						name = this.$t('B2BStatus.cnPartialShipment');
						break;
					case 'n300':
						name = this.$t('B2BStatus.cnOutscan');
						break;
					case '-300':
						name = this.$t('B2BStatus.cnOutscanExceptionPart');
						break;
					case '-302':
						name = this.$t('B2BStatus.cnTransitExceptionCheck');
						break;
					case 'n301':
						name = this.$t('B2BStatus.cnAir');
						break;
					case '-305':
						name = this.$t('B2BStatus.cnTransitException');
						break;
					case '307':
						name = this.$t('B2BStatus.cnTransitExceptionOr');
						break;
					case '-310':
						name = this.$t('B2BStatus.cnAirExceptionDelay');
						break;
					case '-311':
						name = this.$t('B2BStatus.cnAirExceptionCancel');
						break;
					case 'n302':
						name = this.$t('B2BStatus.airArrived');
						break;
					case 'n400':
						name = this.$t('B2BStatus.customsClearanceCompLated');
						break;
					case '-401':
						name = this.$t('B2BStatus.customsClearanceComplatedExceptionCheck');
						break;
					case '-402':
						name = this.$t('B2BStatus.customsClearanceComplatedExceptionDeduction');
						break;
					case 'n510':
						name = this.$t('B2BStatus.overseasArrived');
						break;
					case 'n500':
						name = this.$t('B2BStatus.ksaInscan');
						break;
					case '-520':
						name = this.$t('B2BStatus.cartonToSku');
						break;
					case 'n699':
						name = this.$t('B2BStatus.ksaReadyForDispatch');
						break;
					case 'n700':
						name = this.$t('B2BStatus.delivering');
						break;
					case '-700':
						name = this.$t('B2BStatus.deliveryFailed');
						break;
					case 'n710':
						name = this.$t('B2BStatus.partialreceipt');
						break;
					case 'n800':
						name = this.$t('B2BStatus.successfulDelivery');
						break;
					case '-800':
						name = this.$t('B2BStatus.signingFailed');
						break;
					case '-100':
						name = this.$t('B2BStatus.cancelOrder');
						break;
					case '-600':
						name = this.$t('B2BStatus.transferThirdpartyDelivery');
						break;
						// 新加的轨迹先暂时显示后台返回的状态名称
					default:
						name = row.memo;
						break;
				}
				return name
			},
			// 是否显示展开信息 根据增加classname去区分判断显示展开按钮
			setClassName({
				row,
				index
			}) {
				let classname = 'expand';
				// 默认不显示展开列，在操作列控制显隐 zpy 2022-8-5
				// if(row.subOrderList == null || row.subOrderList.length == 0){
				//     classname = 'expand';
				// }
				return classname
			},
			//创建中和已下单可以修改
			isEditOrder(row) {
				// 审核通过不可修改，（审核通过不改变订单状态，返回审核状态）
				if (!row.examineFlag && (row.status == 'n100' || row.status == '101')) {
					return true;
				} else {
					return false;
				}
			},
			//判断是否可以修改FBA号
			isEditFBA(row) {
				//派送中和全部签收部分签收不可修改
				if (row.status == 'n700' || row.status == 'n710' || row.status == 'n800') {
					return false;
				} else {
					if (row.warehouseType == '1') { //Amazon才有FBA号修改
						return true;
					} else {
						return false;
					}
				}
			},
			
			// 添加订单
			addOrder() {
				this.optType = 'add';
				this.createOrderPopup = true;
			},
			// 修改订单
			edit(row) {
				this.optType = 'edit';
				this.rowData = row;
				this.createOrderPopup = true;
			},
			// 列表更多操作
			handleCommand(val) {
				let row = val.row;
				// 判断操作按钮
				switch (val.command) {
					// 修改订单
					case 'a':
						this.edit(row);
						break;
						// 修改FBA信息
					case 'b':
						this.handleEditFBAInfo(row);
						break;
						// 下载清单
					case 'c':
						this.FBAsheet(row.skuListLink);
						break;
						// 显示分批信息
					case 'd':
						this.handleExpandRow(row);
						break;
				}
			},
			// 通过点击按钮显示箱信息弹窗
			handleCheckPackageInfo(row) {
				this.packageDialogPopup = true;
				this.rowId = row.esnadTobOrderNo;
			},
			// 查看轨迹信息
			handleCheckOTrackingInfo(id){
				this.checkTrackingPopup = true;
				this.rowId = id;
			},
			// 修改FBA
			handleEditFBAInfo(row){
				this.updateFBAPopup = true;
				this.rowId = row.esnadTobOrderNo;
			},
			
			PDFSheet(row) {
				if (row == null) {
					this.$message.warning(this.$t('chinaWhB2BOrderPage.downloadPdfFailedTips'));
				} else {
					window.open(row);
				}
			},
			//FBA附件下载
			FBAsheet(row) {
				window.open(row);
			},
			// 点击展开或收起分批信息
			handleExpandRow(row) {
				row.expand = row.expand ? false : true;
				this.$refs.b2bOrderTable.toggleRowExpansion(row);
			},
			
		},
		created() {
			let customInfo = JSON.parse(window.sessionStorage.getItem("customInfo"));
			this.frontOrafter = customInfo.frontOrafter;
			
			this.getlist();
		},
	}
</script>

<style scoped>
	.listHeader {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	
	.listHeader i {
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	
	.listHeader span {
		display: inline-block;
		float: left;
	}
	
	.app-container {
		padding: 10px;
	}
	/deep/ .expand .el-table__expand-column .cell {
		display: none !important;
	}
	
	.expandDataRow {
		border: 1px solid #a9c5e2;
		margin: 10px auto;
		border-radius: 5px;
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	}
	
	/deep/ .el-table__expand-icon>.el-icon {
		font-size: 18px;
	}
	
	/deep/ .el-table__expand-icon {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	
	/deep/ .el-table__expand-icon .el-icon-arrow-right:before {
		content: '\e791';
	}
	
	/deep/ .el-table__expand-icon--expanded .el-icon-arrow-right:before {
		content: '\e790';
	}
	/deep/ .batchInfoItem{
		width: 20px;
		height: 20px;
		right: -20px;
		top:-2px;
	}
	/* 更多操作中分批信息样式 */
	/deep/ .batchInfoItem1 .is-dot {
		right: 16px;
		top: 6px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
	}
</style>