<template>
  <div :lang="$i18n.locale">
    <!-- 查看订单轨迹信息 -->
    <el-dialog
    :title="$t('commonInfo.trackingTitle')"
    :close-on-click-modal="false"
    :visible.sync="popup"
    width="880px"
    :before-close="handleDialogClose"
    >
      <div class="dialog_orderNo">
      	<label>{{rowId}}</label>
      	<label
      		v-show="dlvdMainDeliveryTime != ''">{{$t('commonInfo.appointmentTime') + '：' + dlvdMainDeliveryTime}}</label>
      </div>
      <el-timeline>
      	<el-timeline-item v-for="(activity, index) in mainActiveData" :key="index" color="green"
      		:timestamp="(activity.initTime)" placement="top" v-show="activity.status==1000?false:true">
      		<el-card v-if="activity.status != 'batchInfo'">
      			<!-- 轨迹区域 -->
      			<h4>{{activity.description}}</h4>
      			<!-- location -->
      			<p v-show="activity.location==false?false:activity.location==null?false:true">
      				{{$t('commonInfo.trackLocation') + ':'}}
      				<span style="font-weight:700">{{activity.location}}</span>
      			</p>
      			<!-- 备注 -->
      			<p>
      				{{$t('commonInfo.remark') + ':'}}
      				<span style="font-weight:700">{{activity.remarks}}</span>
      			</p>
      		</el-card>
      		<!-- 分批信息 -->
      		<el-card v-if="activity.status == 'batchInfo'">
      			<el-row
      				style="background-color: #f9f9f9; margin-bottom: 15px;border-radius: 10px;padding-top: 20px;"
      				v-for="(subOrderData,subOrderIndex) in subActiveData" :key="subOrderIndex">
      				<div class="dialog_subOrderNo">
      					<label>{{$t('commonInfo.batch')+': '+subOrderData[0].esnadTobOrderNo}}</label>
      					<div style="display: inline-flex;" v-for="(trackItem,trackIndex) in subOrderData"
      						:key="trackIndex">
      						<label style="color: #5981c9;font-size: 16px;margin-left: 150px;"
      							v-if="trackItem.status == '1000'">{{$t('commonInfo.appointmentTime') + '：' + trackItem.description}}</label>
      					</div>
      				</div>
      				<!-- 子订单轨迹 -->
      				<el-timeline>
      					<el-timeline-item v-for="(subActivity,subIndex) in subOrderData" :key="subIndex"
      						color="#cbac39" :timestamp="(subActivity.initTime)" placement="top"
      						v-show="subActivity.status==1000?false:true">
      						<p style="font-size:16px">
      							<span style="font-weight:700">{{subActivity.remarks}}</span>
      						</p>
      						<!-- 轨迹区域 -->
      						<h4 style="color:#666;font-size:16px">
      							[ {{subActivity.location}} ]
      							{{subActivity.description}}
      						</h4>
      		 		</el-timeline-item>
      				</el-timeline>
      			</el-row>
      		</el-card>
      	</el-timeline-item>
      </el-timeline>
      <div slot="footer" class="dialog-footer">
      	<el-button type="primary" @click="handleDialogClose">{{$t('commonInfo.dialogBtnConfirm')}}</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    name: 'CheckTrackingInfo',
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      },
      rowId:{
        type:String,
        default:function(){
        	return '';
        }
      },
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
          if(newValue){
            this.popup = newValue;
            this.getTrackingList(this.rowId);
          }
      	},
      	immediate: true,
      },
    },
    data() {
      return {
        popup:false,
        dlvdMainDeliveryTime:'',
        activities: [],
        mainActiveData: [], //主订单轨迹
        subActiveData: [], //子订单轨迹
        warehouseTime: "",
        onRemarks: "",
        showMore:false,
      }
    },

    //方法集合
    methods: {
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
        this.dlvdMainDeliveryTime = '';
				this.activities = [];
				this.mainActiveData = [];
				this.subActiveData = [];
				this.warehouseTime = '';
				this.onRemarks = '';
				this.showMore = false;
        this.$parent.checkTrackingPopup = false;
        // 清除数据
        this.$parent.rowId = '';
      },
			// 获取数据
			async getTrackingList(id) {
				const res = await this.$http.get(
					"/toborder/trackinfo/" + id
				);
				if (res.code === 200) {
					this.activities = res.data;
					this.mainActiveData = this.activities.mainOrderTrackingList;
					this.subActiveData = this.activities.subOrderTrackingList;
					// 增加分批轨迹信息 如果有返回子订单轨迹则再主订单轨迹入库完成后面添加对象，来显示子订单分批信息 zpy 2022-7-29
					if (this.subActiveData.length > 0) {
						let index = this.mainActiveData.findIndex((item) => item.status == 'n200');
						this.mainActiveData.splice(index, 0, {
							initTime: this.$t('chinaWhB2BOrderPage.batchTrackingTitle'),
							status: 'batchInfo',
						})
						this.subActiveData.forEach(item => {
							item.forEach(subItem => {
								subItem.initTime = subItem.initTime + ' Time Zone：Beijing';
							})
			
						})
					}
					if (this.mainActiveData.length === 0) {
						//输入的订单号不存在
						this.$message.error(this.$t('tipsInfo.orderNoExistTips'));
					}
					this.mainActiveData.forEach(item => {
						if (item.status == '1000') {
							this.dlvdMainDeliveryTime = item.description
						}
						if (item.status == 1000 && item.id) {
							this.warehouseTime = item.description;
							this.onRemarks = item.remarks;
							this.showMore = true;
						}
						if (item.initTime != this.$t('chinaWhB2BOrderPage.batchTrackingTitle')) {
							item.initTime = item.initTime + ' Time Zone：Beijing';
						}
					});
			
			
				}
			},
      
			
    },
    created() {
    },

  }
</script>

<style scoped>
	.dialog_orderNo {
		width: 100%;
		height: 50px;
		line-height: 40px;
		margin-bottom: 5px;
	}
	
	.dialog_orderNo label {
		margin-left: 40px;
		font-weight: bold;
		border-radius: 8px;
		font-size: 20px;
		padding: 9px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	/* 子订单号 */
	.dialog_subOrderNo {
		width: 100%;
		height: 40px;
		line-height: 30px;
	}
	
	.dialog_subOrderNo label {
		margin-left: 20px;
		border-radius: 6px;
		font-size: 16px;
		padding: 5px 10px;
	}
	
	[lang='ar'] /deep/ .el-timeline-item__node--normal {
		right: -15px;
		left: auto;
	}
	[lang='ar'] /deep/ .el-timeline-item__tail {
		right: -20px;
		left: auto;
	}
	[lang='ar'] /deep/ .dialog-footer {
		text-align: left; 
		margin-left: 25px;
	}
</style>
